import React from 'react';
import { Link } from 'gatsby';
import { MainLayout } from '../components/Layout';
import ZoneMap from '../components/Map/ZoneMap';
import { slashPrefix, sliceIntoNArrays } from '../utils/utils';

export default ({ data, pageContext }) => {
  const propertyData = pageContext.property;

  const zoneData = pageContext.zone;
  const zoneStreets = pageContext.zoneStreets;

  const productCategoryData = pageContext.productCategory;
  const servicesCategories = pageContext.servicesCategories;
  const itemsCategories = pageContext.itemsCategories;

  return (
    <MainLayout
      siteTitle={
        productCategoryData.seoTitle +
        ', ' +
        propertyData.name +
        ' - Quartier ' +
        zoneData.name +
        ' à ' +
        zoneData.region
      }
    >
      <section className="container section-zone">
        <div className="row">
          <div className="col-12 text-align-center">
            <div className="roboto bold mt-10 mt-5-md pl-2-md pr-2-md font-size-xxl font-size-xl-md">
              Vous recherchez{' '}
              <span className="yellowTag">{productCategoryData.seoTitle}</span>{' '}
              proche {propertyData.name.match(/^\d/) ? 'du' : 'de'}{' '}
              <Link
                to={`/ma-residence${slashPrefix(propertyData.slug)}/`}
                className="yellowTag text-decoration-none"
              >
                {propertyData.name}
              </Link>{' '}
              à {propertyData.units.map((u) => u.address.city)[0]} dans le
              quartier{' '}
              <Link
                to={`/quartiers${slashPrefix(zoneData.slug)}/`}
                className="text-decoration-none cursor-pointer"
              >
                <span className="yellowTag">{propertyData.zoneName}</span>
              </Link>{' '}
              ?
            </div>

            <div className="roboto mt-10 mt-5-md pl-2-md pr-2-md font-size-xl">
              Alacaza vous permet de proposer et d'échanger simplement des biens
              et des services entre voisins !
              <br />
              Profitez du réseau de vos voisins sur Alacaza autour{' '}
              {propertyData.name.match(/^\d/) ? 'du' : 'de'} {propertyData.name}{' '}
              pour trouver {productCategoryData.seoTitle} !
            </div>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-4 hidden-sm"></div>

          <div className="col-4 col-12-sm pl-2-md pr-2-md">
            <div className="font-size-xl text-align-center pb-1 bold roboto">
              Rejoignez tout de suite vos voisins !
            </div>

            <div className="block text-align-center mt-5">
              {/*<Link to={`/inscription/form/?p=${propertyData._id}`} className="btn big block">Je m'inscris</Link>*/}
              <Link
                to={'/inscription/form/'}
                state={{ propertyId: propertyData.id, from: 'propertyPage' }}
                className="btn big block"
              >
                Je m'inscris
              </Link>
            </div>
          </div>
          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10 mt-5-md">
          <div className="col-4 hidden-sm"></div>

          <div className="col-4 col-12-sm">
            <div className="zone-map text-align-right text-align-center-md pl-5-md pr-5-md mt-10-md">
              <ZoneMap
                zoneCoordinates={zoneData.location?.coordinates}
                propertyLocation={propertyData.location}
              />
            </div>

            <div className="pt-2 font-size-xs text-align-center text-color-purple-grey">
              {productCategoryData.name} -{' '}
              {propertyData.units
                .map((propertyUnit) => propertyUnit.address.fullAddress)
                .join(', ')}
            </div>
          </div>

          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md bold pb-1">
              Quartier {zoneData.name} à {zoneData.region}
            </div>
            <hr />

            {sliceIntoNArrays(zoneStreets, 4).map((streets, index) => (
              <div className="col-3 pt-1 font-size-sm">
                <ul className="full-width">
                  {streets.map((value, index) => (
                    <li className="pb-2">
                      <Link to={`${value.slug}/`} activeClassName="bold">
                        <span className="pr-1">
                          {value.streetName +
                            ' à ' +
                            value.city +
                            ' (' +
                            value.postalCode +
                            ')' +
                            (index + 1 < zoneStreets.length ? ',' : '')}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md2 bold pb-1">
              Petites annonces proches de {propertyData.name} dans le quartier{' '}
              {zoneData.name} à {zoneData.region}
            </div>
            <hr />

            {sliceIntoNArrays(servicesCategories, 2).map(
              (categories, index) => (
                <div className="col-3 pt-1">
                  <ul className="full-width">
                    {categories.map((value, index) => (
                      <li className="pb-2 pr-2">
                        <div className="font-size-md">
                          <Link
                            to={`/petites-annonces/services${slashPrefix(
                              value.slug,
                            )}${slashPrefix(propertyData.slug)}/`}
                            activeClassName="bold"
                          >
                            Services > {value.name}
                          </Link>
                        </div>
                        <div className="font-size-xs pt-1">
                          <Link
                            to={`/petites-annonces/services${slashPrefix(
                              value.slug,
                            )}${slashPrefix(propertyData.slug)}/`}
                            activeClassName="bold"
                          >
                            {value.seoTitle} dans le quartier {zoneData.name} au{' '}
                            {propertyData.name}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ),
            )}

            {sliceIntoNArrays(itemsCategories, 2).map((categories, index) => (
              <div className="col-3 pt-1">
                <ul className="full-width">
                  {categories.map((value, index) => (
                    <li className="pb-2 pr-2">
                      <div className="font-size-md">
                        <Link
                          to={`/petites-annonces/bien${slashPrefix(
                            value.slug,
                          )}${slashPrefix(propertyData.slug)}/`}
                          activeClassName="bold"
                        >
                          Biens > {value.name}
                        </Link>
                      </div>
                      <div className="font-size-xs pt-1">
                        <Link
                          to={`/petites-annonces/biens${slashPrefix(
                            value.slug,
                          )}${slashPrefix(propertyData.slug)}/`}
                          activeClassName="bold"
                        >
                          {value.seoTitle} dans le quartier {zoneData.name} au{' '}
                          {propertyData.name}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
